import { divide, __ } from 'ramda'

export const toJSON = raw => raw.json()

export const half = divide(__, 2)

export const sortByName = data =>
  data.sort((a, b) => (a.name > b.name ? 1 : -1))

export const formatValue = value =>
  value !== null ? value.toFixed(1) : 'Sem dados'

export function zipWith(after) {
  const args = arguments,
    lengthArgs = args.length
  let length = 9999999,
    lengthCurrent = 0,
    idCurrent = 0

  for (let index = 1; index < lengthArgs; index++) {
    lengthCurrent = args[index].length

    if (lengthCurrent < length) {
      length = lengthCurrent
      idCurrent = index
    }
  }

  const teste2 = [...arguments[idCurrent]]

  function returnValuesArray(index) {
    let t = []
    for (let i = 1; i < lengthArgs; i++) t[i - 1] = args[i][index]

    return t
  }

  return teste2.map((_, index) => after(...returnValuesArray(index)))
}
