import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStore } from 'easy-peasy'
import { prop } from 'styled-tools'

const Content = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: ${prop('theme.zindex.above')};
`

export function MapCaption() {
  const layer = useStore(({ map }) => map.caption)
  const [image, setImage] = useState(null)

  const load = name =>
    import(`tbg/resources/captions/${name}.svg`).then(res =>
      setImage(res.default)
    )

  useEffect(() => {
    layer ? load(layer) : setImage(null)
  }, [layer])

  return <Content>{image && <img src={image} />}</Content>
}
