import React, { memo, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { prop } from 'styled-tools'
import PropTypes from 'prop-types'

import { Text, Icon } from '@somarmeteorologia/momentum'

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${prop('height')}px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Circle = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const State = memo(({ state, height }) => {
  const { text } = useContext(ThemeContext)

  const states = {
    loading: {
      name: 'loading',
      title: 'Carregando resultados',
      description: 'Aguarde enquanto estamos carregando os resultados.'
    },
    error: {
      name: 'close',
      title: 'Erro de carregamento',
      description:
        'Estamos enfrentando problemas nos nossos serviços. Tente novamente mais tarde.'
    }
  }

  return (
    <Container height={height}>
      <Circle>
        <Icon
          name={states[state].name}
          color={text.disabled}
          width={80}
          height={80}
        />
      </Circle>

      <Text.Heading
        top={30}
        size={Text.Heading.size.twentyEight}
        color={text.primary}
      >
        {states[state].title}
      </Text.Heading>

      <Text.P top={10} size={Text.size.sixteen}>
        {states[state].description}
      </Text.P>
    </Container>
  )
})

State.defaultProps = {
  height: 70,
  state: 'loading'
}

State.propTypes = {
  height: PropTypes.number,
  state: PropTypes.oneOf(['loading', 'error'])
}
