import { toJSON, sortByName, formatValue } from './utils'
import { Env } from 'tbg/environment'

const URI = resource => `${Env.getEnv(Env.BROLY_URI)}/${resource}`

const headersConfig = {
  headers: {
    'x-api-key': Env.getEnv(Env.BROLY_KEY)
  }
}

const normalize = ({
  segments,
  temperatures,
  precipitations,
  accum_precipitations
}) =>
  sortByName([
    ...segments.map((segment, index) => ({
      segments: segment,
      temperatures: formatValue(temperatures[index]),
      precipitations: formatValue(precipitations[index]),
      accum_precipitations: formatValue(accum_precipitations[index])
    }))
  ])

export const normalizeDailyRecords = ({ periods, points }) => [
  ...periods.map((period, index) => ({
    period,
    max_temperature: formatValue(points.observed.max_temperature[index]),
    min_temperature: formatValue(points.observed.min_temperature[index]),
    precipitations: formatValue(points.observed.precipitations[index]),
    slide: formatValue(points.observed.alert_slide[index]),
    alerts: points.observed.alert_precipitation[index]
  }))
]

export function fetchHourlyRecords() {
  return fetch(URI('portal/historical/hourly'), headersConfig)
    .then(toJSON)
    .then(({ data, updated_at }) => ({
      updated_at,
      data: normalize(data)
    }))
}

export const fetchDailyRecords = async ({ point }) => {
  try {
    const fetched = await fetch(
      URI(`observed/daily?point=${point}&days=30`),
      headersConfig
    )

    if (!fetched.ok) {
      throw new Error(fetched.status)
    }

    const parsed = await toJSON(fetched)

    return {
      updated_at: parsed.meta.update_at,
      data: normalizeDailyRecords(parsed)
    }
  } catch (error) {
    throw new Error(error)
  }
}
