import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useStore } from 'easy-peasy'
import { ifProp } from 'styled-tools'

import { MODULE, PAGE } from 'tbg/config'
import { Sidebar, Navigation } from 'tbg/components'
import { Snackbar, Text } from '@somarmeteorologia/momentum'

const Container = styled.div`
  display: flex;
  position: relative;

  ${ifProp(
    { hasOverflow: true },
    css`
      overflow: hidden;
    `
  )}
`

const Resizable = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
`

const isNavigationShowable = (module, page) => {
  const modules = [MODULE.monitoring.id]
  const pages = [PAGE.map.id]

  return !(pages.includes(page.id) && modules.includes(module.id))
}

export function Menu({ children }) {
  const [toast, setToast] = useState(false)

  const toOpenToast = () => setToast(true)
  const toCloseToast = () => setToast(false)

  const { module, page } = useStore(({ config }) => ({
    module: config.module,
    page: config.page
  }))

  const hasOverflow = () => page.id === PAGE.map.id

  return (
    <Container hasOverflow={hasOverflow()}>
      <Snackbar
        data-testid="snackbar"
        duration={3}
        isOpen={toast}
        toClose={toCloseToast}
        description={({ size, color }) => (
          <Text size={size} color={color}>
            Não é possível visualizar a camada, ative a primeiro.
          </Text>
        )}
      />

      <Sidebar toOpenToast={toOpenToast} />

      <Resizable>
        {isNavigationShowable(module, page) && <Navigation />}
        {children}
      </Resizable>
    </Container>
  )
}
