import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { theme } from 'styled-tools'
import posed from 'react-pose'

import {
  Tooltip,
  Text,
  Button as Buttonable,
  Icon
} from '@somarmeteorologia/momentum'

const Container = posed(styled.div`
  display: flex;
  width: calc(100% - 20px);
  margin: 0 auto;
  height: 145px;
  justify-content: center;
  align-items: flex-end;
  background-color: ${theme('navigation.bg.primary')};
  flex-direction: column;
`)({
  visible: {
    opacity: 1
  },
  collapsed: {
    opacity: 0
  }
})

/**
 * @todo Adicionar Box ao componente Button
 */
const Button = styled(Buttonable)`
  margin-bottom: 15px;
`

const Wrapper = styled.div`
  line-height: 14px;
`

export function Footer({ toggled, ...props }) {
  const { text } = useContext(ThemeContext)

  return (
    <Container {...props} toggled={toggled}>
      {!toggled && (
        <>
          <Tooltip
            interactive
            distance={35}
            size={Tooltip.size.small}
            appearence={Tooltip.appearence.accent}
            trigger={Tooltip.trigger.click}
            header={({ color, size, weight }) => (
              <Text color={color} size={size} weight={weight}>
                Precisa de ajuda?
              </Text>
            )}
            body={({ color, size }) => (
              <Text color={color} size={size}>
                Fale com um de nossos meteorologistas.
                <br />
                Eles estão disponíveis das 07h00 às 19h00.
                <Text
                  color={color}
                  size={size}
                  weight={Text.weight.bold}
                  top={5}
                >
                  Central de atendimento: (11) 3030-0799
                </Text>
              </Text>
            )}
          >
            <Button
              appearence={Button.appearence.rounded}
              size={Button.size.large}
              full
            >
              <Wrapper>
                <Text
                  weight={Text.weight.bold}
                  size={Text.size.twelve}
                  color={text.primary}
                >
                  Precisa de ajuda?
                </Text>
                <Text size={Text.size.ten} color={text.primary}>
                  Fale com um meteorologista!
                </Text>
              </Wrapper>
            </Button>
          </Tooltip>
        </>
      )}
    </Container>
  )
}
