import React, { memo, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { prop } from 'styled-tools'
import PropTypes from 'prop-types'

import { Text, Icon } from '@somarmeteorologia/momentum'

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${prop('height')}px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Circle = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Loading = memo(({ height }) => {
  const { text } = useContext(ThemeContext)

  return (
    <Container height={height}>
      <Circle>
        <Icon name="loading" color={text.disabled} width={80} height={80} />
      </Circle>

      <Text.Heading
        top={30}
        size={Text.Heading.size.twentyEight}
        color={text.primary}
      >
        Carregando
      </Text.Heading>

      <Text.P top={10} size={Text.size.sixteen}>
        A aplicação está sendo carregada, aguarde.
      </Text.P>
    </Container>
  )
})

Loading.defaultProps = {
  height: 0
}

Loading.propTypes = {
  height: PropTypes.number
}
