import React, { useEffect, useContext } from 'react'
import { useStore } from 'easy-peasy'

import { Navigation as Nav } from '@somarmeteorologia/momentum'
const { Context, useInterable } = Nav

import { fetchPointsById, fetchCitiesAndPoints } from 'tbg/services'

export default function External({ setToggled }) {
  const { recordsType, toOpen } = useStore(({ config, records }) => ({
    toOpen: config.toOpen,
    recordsType: records.type
  }))

  const interable = useContext(Context)
  const withInterable = useInterable(interable)
  const [setRecordsContent] = withInterable('recordsContent')
  const [setForecastContent] = withInterable('forecastContent')

  const isRecordsAutocompleteVisible = () => {
    const withAutocomplete = ['daily', 'monthly']

    return withAutocomplete.includes(recordsType)
  }

  const onToggled = toggle => setToggled(toggle)

  useEffect(() => {
    setRecordsContent('recordsAutocomplete', isRecordsAutocompleteVisible())
  }, [recordsType])

  useEffect(() => {
    let isMounted = true

    fetchPointsById().then(
      data => isMounted && setRecordsContent('points', data)
    )
    fetchCitiesAndPoints().then(
      data => isMounted && setForecastContent('citiesAndPoints', data)
    )

    return () => (isMounted = false)
  }, [])

  return (
    <Nav
      height="calc(100% - 215px)"
      toOpen={() => toOpen}
      onToggled={onToggled}
    />
  )
}
