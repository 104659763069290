import moment from 'moment'

const URI = day =>
  `https://wrf-imagery.s3.amazonaws.com/observed/prec_${day}days.png`

export function getRain() {
  const urls = []
  let date

  for (let i = 1; i <= 30; i++) {
    date = moment().subtract(i, 'day')

    urls.push({
      date: date.format('DD MMMM HH:mm'),
      url: URI(i)
    })
  }

  return urls
}
