import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { ThemeProvider } from 'styled-components'
import { StoreProvider } from 'easy-peasy'
import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'

import Router from './Router'
import { Theme, Reset } from '@somarmeteorologia/momentum'

import { store } from 'tbg/store'
import { Env } from 'tbg/environment'

Env.isProduction() && Sentry.init({ dsn: Env.getEnv(Env.SENTRY) })

const Background = createGlobalStyle`
  body {
    background-color: ${theme('navigation.bg.primary')};
  }
`

ReactDOM.render(
  <StoreProvider store={store}>
    <ThemeProvider theme={Theme.light}>
      <>
        <Reset />
        <Background />
        <Router />
      </>
    </ThemeProvider>
  </StoreProvider>,
  document.getElementById('root')
)
