import moment from 'moment'

const URI = (type, layer, hoursAhead) =>
  `https://s3.amazonaws.com/wrf-imagery/${type}/wrf15_${layer}_model_overlay${hoursAhead}.png`

const isHourly = type => type === 'hour'

const now = parseInt(moment().format('HH'))

export function getWrf(type, layer) {
  const step = isHourly(type) ? 1 : 6
  const start = isHourly(type) ? now : Math.floor(now / 6) * 6
  const end = start + 72
  const urls = []
  let iterator = 0

  for (let i = start; i <= end; i += step) {
    urls.push({
      date: moment()
        .add(iterator, 'h')
        .format('DD MMMM HH:mm'),
      url: URI(type, layer, i)
    })

    iterator++
  }

  return urls
}
