import React from 'react'

import { Navigation } from '@somarmeteorologia/momentum'
import { PAGE } from 'tbg/config'
import { useStore } from 'easy-peasy'

const { Provider } = Navigation

import Structure from './Structure'
import Interable from './Interable'
import External from './External'

const isRadarsReady = radars => !!radars.length

export function Navigable({ toOpenToast, setToggled }) {
  const { radars, page } = useStore(({ radars, config }) => ({
    radars: radars.data,
    page: config.page
  }))

  const isReady = () => {
    const withAsyncContent = [PAGE.map.id]

    return withAsyncContent.includes(page.id) ? isRadarsReady(radars) : true
  }

  return (
    <>
      {isReady() ? (
        <Interable>
          {({ interable, onInterable }) => (
            <Structure toOpenToast={toOpenToast}>
              {({ structure }) => (
                <Provider
                  defaultInterables={interable}
                  onInterable={onInterable}
                  defaultStructure={structure}
                >
                  <External toOpenToast={toOpenToast} setToggled={setToggled} />
                </Provider>
              )}
            </Structure>
          )}
        </Interable>
      ) : null}
    </>
  )
}
