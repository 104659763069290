import * as yup from 'yup'

export const validateGeoResume = async data => {
  const isValid = await schemaGeoResume().isValid(data)

  if (!isValid) {
    const { message } = await schemaGeoResume().validate(data)

    throw new Error(message)
  }

  return data
}

export const schemaGeoResume = () => {
  const Type = yup
    .array()
    .of(yup.number().nullable())
    .min(3)
    .max(3)
    .required()

  const String = yup.string().nullable()

  return yup.object({
    features: yup
      .array(
        yup.object({
          geometry: yup.object({
            coordinates: yup.array(),
            type: yup.string()
          }),
          type: yup.string(),
          properties: yup.object({
            DATA: yup.object({
              humidity: Type,
              lightning: Type,
              precipitation_accumulated_72h: yup
                .number()
                .nullable()
                .required(),
              slipping: Type,
              strong_rain: Type,
              wind_speed: Type
            }),
            DESCRIPTION: yup.string().required(),
            NAME: yup.string().required(),
            TYPE: yup.string().required(),
            TYPE_SEGMENT: yup.string().required()
          })
        })
      )
      .of(yup.string().nullable())
      .required(),
    meta: yup.object({
      units: yup.object({
        date_update_forecast: String,
        date_update_observed: String,
        humidity: String,
        lightning: String,
        precipitation_accumulated: String,
        slipping: String,
        strong_rain: String,
        wind_speed: String
      })
    })
  })
}
